// services.js

import { t } from "i18next";

export const fetchWithAuth = async (url, options = {}, localStorageKeys = []) => {
  // The JWT token will now be automatically included in the request via cookies

  // The new full URL for API requests
  const fullUrl = `/api${url}`;

  const headers = {
    'Content-Type': 'application/json',
    ...options.headers,
    credentials: 'include',  // Ensure that cookies (including the JWT) are sent
  };

  // If there are additional keys in localStorage, we can still include them
  localStorageKeys.forEach(key => {
    const value = localStorage.getItem(key);
    if (value) {
      headers[key] = value;
    }
  });

  // Perform the request
  const response = await fetch(fullUrl, {
    ...options,
    headers,
  });

  if (!response.ok) {
    throw new Error(t('somethingWentWrong'));
  }

  return response.json();
};


export const postWithAuth = async (url, options = {}, formData, localStorageKeys = []) => {
  // The new full URL for API requests
  const fullUrl = `/api${url}`;

  const headers = {
    // Custom headers can go here if needed, but typically form data doesn't need 'Content-Type'
    ...options.headers, // Include any custom headers passed in options
  };

  // If there are additional keys in localStorage, we can still include them
  localStorageKeys.forEach(key => {
    const value = localStorage.getItem(key);
    if (value) {
      headers[key] = value;
    }
  });

  // Perform the request with form data
  const response = await fetch(fullUrl, {
    method: 'POST', // Default method for form submission
    credentials: 'include', // Send JWT via cookies
    headers,  // Custom headers (if any)
    body: formData, // The actual form data (should be a FormData object)
  });

  if (!response.ok) {
    throw new Error(t('somethingWentWrong'));
  }

  return response.json();
};


export const fetchUserInfo = async (url, setUserInfo, setLoading, setError, keys=[]) => {
  
    const data = await fetchWithAuth(url, {}, keys);
    setUserInfo(data);

    setLoading(false);
  
};



export const formatDate = (dateString) => {
  if (!dateString) return 'Invalid date';

  const date = new Date(dateString);
  if (isNaN(date.getTime())) return 'Invalid date';

  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Intl.DateTimeFormat('en-US', options).format(date);
};


export const logout = async () => {
  const i18nextLng = localStorage.getItem('i18nextLng'); // Retrieve the i18nextLng value
  localStorage.clear(); // Clear all items from localStorage
  if (i18nextLng) {
      localStorage.setItem('i18nextLng', i18nextLng); // Restore the i18nextLng value
  }

  // Make a request to the backend to clear the JWT cookie
  await fetch('/api/logout', {
    method: 'POST',
    credentials: 'include'  // Ensure cookies are sent with the request
  });

  // Redirect to login page
  window.location.href = '/login';
};


export const handleImageUpload = async (file, uploadUrl, setError, setShowToast) => {
  const formData = new FormData();
  formData.append('file', file); // Append the image file

  try {
    const response = await fetch(uploadUrl, {
      method: 'POST',
      headers: {
        'Selectedcompanyid': localStorage.getItem('selectedCompanyId')
      },
      body: formData,
    });

    if (response.ok) {
      const data = await response.json();
      console.log('Image uploaded successfully:', data.message);
      window.location.reload(); // Reload the window after successful upload
    } else {
      const errorData = await response.json();
      throw new Error(errorData.message || t('somethingWentWrong')); // Create an error for the catch block
    }
  } catch (error) {
    setError(t('somethingWentWrong')); // Set error message in state
    setShowToast(true); // Show the toast notification when there's an error
    console.error('Error:', error.message);
  }
};


