import React from 'react';
import { useEffect, useState } from 'react';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import Screen from '../../components/common/Screen';
import Card from '../../components/elements/Card';
import InputField from '../../components/forms/InputField';
import Button from '../../components/elements/Button';
import { Link } from 'react-router-dom';
import Toast from '../../components/forms/Toast';


const Page = () => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(null); 
  const [error, setError] = useState(null);
  const [showToast, setShowToast] = useState(false); 

  const onSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const values = Object.fromEntries(formData.entries());

    try {
      const response = await fetch('/api/forgot-password', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(values)
      });
  
      if (response.ok) {
        const data = await response.json();
        setError(null); // Reset error on success
        setSuccess(data.message); // Set success message
        setShowToast(true); // Show toast notification
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || t('somethingWentWrong'));
      }
    } catch (error) {
      setError(t('somethingWentWrong'));
      setSuccess(null); // Clear success message if there's an error
      setShowToast(true); // Show toast notification when there's an error
    }

  };

  return (
    <Screen backgroundImage={require('./../../assets/images/login.png')}>
      <div className="center">
        
      <form onSubmit={onSubmit}>
        <Card style={{width: '18rem', opacity: '0.9'}} >
          <Title>{t("forgotPassword")}</Title>
          <p className='my-4'>{t("forgotPasswordInstructions")}</p>
          <InputField type="email" name="email" label={t("email")} placeholder={t("enterEmail")} />
          <Button mb="2" type='submit'>{t("sendEmail")}</Button>
          <div className='mb-3'><Link to="/login" className='link-active'>
            {t("backToLogin")}
          </Link></div>

          <Toast showToast={showToast} setShowToast={setShowToast} message={success ? t(success) : t(error)} icon={success ? "check-circle" : "exclamation"} />
        </Card>
      </form>

      </div>
      
    </Screen>
  );
};

export default Page;